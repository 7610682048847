<template>
  <div class="permission-setting">
    <b-row class="content">
      <b-col class="col1" md="4">
        <h4>Ensembles</h4>
        <b-list-group>
          <b-list-group-item
            button
            v-for="ensemble in getListePermissionSet"
            :key="ensemble.id"
            @click="handleClickPermissionSet(ensemble)"
          >
            <div class="name-permissionSet">
              <div
                class="icon"
                v-if="
                  selectedPermissionSet &&
                    selectedPermissionSet.id == ensemble.id
                "
              >
                <font-awesome-icon icon="check" />
              </div>
              <div class="capitalize">
                {{ ensemble.name }}
              </div>
            </div>
            <b-dropdown
              class="dropDown"
              size="lg"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              text="..."
            >
              <b-dropdown-item @click.stop="handleUpdateClick(ensemble)">
                <font-awesome-icon
                  class="iconDropDown"
                  icon="pencil-alt"
                />Renommer
              </b-dropdown-item>
              <b-dropdown-item @click.stop="handleDeleteClick(ensemble)">
                <font-awesome-icon
                  class="iconDropDown"
                  icon="trash-alt"
                />Supprimer</b-dropdown-item
              >
            </b-dropdown>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col class="col2" md="4">
        <h4>Utilisateurs</h4>
        <div class="bloc-select-multi" v-if="selectedPermissionSet">
          <multiselect
            v-model="selectedUsers"
            :options="listUserComputed"
            placeholder="Rechercher l'utilisateur"
            :multiple="true"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            label="full_name"
            track-by="id"
            :preselect-first="false"
          >
          </multiselect>
          <div class="postion-btn" v-if="selectedUsers && selectedUsers.length">
            <b-button
              class="button-add-users"
              variant="success"
              @click="handleSelectUsers(selectedUsers)"
              >Enregistrer</b-button
            >
          </div>
        </div>
        <div class="addListUsers" v-if="selectedPermissionSet">
          <div v-if="getUpdateUsersLoading" class="three-dots-loading">
            Chargement en cours
          </div>
          <div
            v-if="getUsersLoading || getPermissionLoading"
            class="three-dots-loading"
          >
            Chargement en cours
          </div>
          <b-list-group v-else>
            <b-list-group-item
              v-for="item in selectedPermissionSet.users"
              :key="item.id"
            >
              <div class="user">
                {{ item.full_name }}<br />
                <strong class="type"> {{ item.email }}</strong>
              </div>
              <div
                class="button"
                @click="handleDeleteUserClick(item)"
                title="Supprimer"
              >
                X
              </div>
            </b-list-group-item>
          </b-list-group>
          <div
            class="chargement"
            v-if="
              !getUsersLoading &&
                !getPermissionLoading &&
                !getUpdateUsersLoading &&
                selectedPermissionSet.users &&
                selectedPermissionSet.users.length == 0
            "
          >
            Aucun utilisateur
          </div>
        </div>
      </b-col>
      <b-col class="col3" md="4">
        <h4>Autorisations</h4>
        <div v-if="selectedPermissionSet">
          <div v-if="getPermissionUpdateLoading" class="three-dots-loading">
            Chargement en cours
          </div>
          <div v-if="getPermissionLoading" class="three-dots-loading">
            Chargement en cours
          </div>
          <div v-else>
            <b-form-checkbox
              v-for="item in getListePermission"
              :key="item.id"
              v-model="selectedPermission[item.id]"
              switch
              @change="UpdateAutorisation($event, item)"
            >
              {{ item.name }}
            </b-form-checkbox>
          </div>
        </div>
      </b-col>
    </b-row>
    <div class="three-dots-loading margin mt-4" v-if="initLoading">
      Chargement en cours
    </div>
    <div
      class="aucun-permission mt-4"
      v-if="
        (!getListePermissionSet || !getListePermissionSet.length) &&
          !initLoading
      "
    >
      Aucun ensemble d'autorisation
    </div>
    <!-- update Permission Modal -->
    <b-modal
      no-close-on-backdrop
      id="ModalUpdate"
      ref="ModalUpdate"
      title="Renommer un ensemble d'autorisations"
      :hide-footer="true"
    >
      <form
        v-if="permissionSetToUpdate"
        @submit.prevent="modifierPermissionSet"
      >
        <b-form-group label="Nom " label-for="nom-input-update">
          <b-form-input
            id="Nom-input"
            v-model="nameToUpdate"
            required
          ></b-form-input>
        </b-form-group>
        <div class="message">
          <div v-if="getPermissionLoading" class="three-dots-loading">
            Chargement en cours
          </div>
          <div v-if="getPermissionError" class="error">
            <ul v-if="Array.isArray(getPermissionError)">
              <li v-for="(e, index) in getPermissionError" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getPermissionError }}</span>
          </div>
        </div>
        <div class="form-actions">
          <b-button
            @click="hideModal('ModalUpdate')"
            variant="outline-secondary"
          >
            Annuler
          </b-button>
          <b-button variant="success" type="submit">
            Enregistrer
          </b-button>
        </div>
      </form>
    </b-modal>
    <!-- Delete Permission Modal -->
    <b-modal
      no-close-on-backdrop
      ref="ModalDelete"
      id="ModalDelete"
      title=" Supprimer un ensemble d'autorisations"
      :hide-footer="true"
    >
      <p>
        Êtes-vous certain de vouloir supprimer l'ensemble d'autorisation
        <strong>
          {{ permissionSetToDelete ? permissionSetToDelete.name : '' }}</strong
        >
        ?
      </p>
      <div class="message">
        <div v-if="getPermissionLoading" class="three-dots-loading">
          Chargement en cours
        </div>
        <div v-if="getPermissionError" class="error">
          <ul v-if="Array.isArray(getPermissionError)">
            <li v-for="(e, index) in getPermissionError" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ getPermissionError }}</span>
        </div>
      </div>
      <div class="form-actions">
        <b-button @click="hideModal('ModalDelete')" variant="outline-secondary">
          Annuler
        </b-button>
        <b-button @click="SupprimerPermissionSet" variant="success">
          Valider
        </b-button>
      </div>
    </b-modal>
    <!-- Delete utilisteur -->
    <b-modal
      no-close-on-backdrop
      ref="ModalDeleteUsers"
      id="ModalDeleteUsers"
      title="Supprimer un utilisateur"
      :hide-footer="true"
    >
      <p>
        Êtes-vous certain de vouloir supprimer l'utilisateur
        <strong>
          {{
            permissionSetToDeleteUsers
              ? permissionSetToDeleteUsers.full_name
              : ''
          }}</strong
        >
        ?
      </p>
      <div class="message">
        <div v-if="getPermissionLoading" class="three-dots-loading">
          Chargement en cours
        </div>
        <div v-if="getPermissionError" class="error">
          <ul v-if="Array.isArray(getPermissionError)">
            <li v-for="(e, index) in getPermissionError" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ getPermissionError }}</span>
        </div>
      </div>
      <div class="form-actions">
        <b-button
          @click="hideModal('ModalDeleteUsers')"
          variant="outline-secondary"
        >
          Annuler
        </b-button>
        <b-button @click="supprimerUserPermissionSet" variant="success">
          Valider
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'permission',
  data() {
    return {
      initLoading: true,
      selectedUsers: [],
      selectedPermissionSet: null,
      selectedPermission: [],
      nameToUpdate: null,
      permissionToAdd: {
        name: null,
        permission: null
      },
      permissionSetToUpdate: null,
      permissionSetToDelete: null,
      permissionSetToDeleteUsers: null
    }
  },
  methods: {
    ...mapActions([
      'updateUserPermissionSet',
      'deleteUserPermissionSet',
      'deletePermissionSet',
      'updatePermissionPermissionSet',
      'updateNamePermissionSet',
      'fetchListePermission',
      'fetchListePermissionSet',
      'fetchListeUsersPermission',
      'fetchListeUsersAvecPermissionSets',
      'fetchListePermissionSets',
      'resetErrorPermission'
    ]),
    resetModal() {
      this.permissionToAdd = {
        name: null,
        permission: null
      }
      this.selectedPermission = []
      this.selectedPermissionSet = null
      this.permissionSetToUpdate = null
      this.permissionSetToDelete = null
      this.permissionSetToDeleteUsers = null
      this.resetErrorPermission()
    },
    hideModal(ref) {
      this.$refs[ref].hide()
    },
    async handleSelectUsers(user) {
      const response = await this.updateUserPermissionSet({
        ensemble: this.selectedPermissionSet,
        usersToAdd: user
      })
      if (response) {
        this.selectedUsers = []
      }
    },
    async SupprimerPermissionSet() {
      await this.deletePermissionSet(this.permissionSetToDelete)
      this.hideModal('ModalDelete')
      this.resetModal()
    },
    handleUpdateClick(permissionSet) {
      this.permissionSetToUpdate = permissionSet
      this.nameToUpdate = permissionSet.name
      this.$refs['ModalUpdate'].show()
    },
    handleDeleteClick(permissionSet) {
      this.permissionSetToDelete = permissionSet
      this.$refs['ModalDelete'].show()
    },
    handleDeleteUserClick(user) {
      this.permissionSetToDeleteUsers = user
      this.$refs['ModalDeleteUsers'].show()
    },
    async handleClickPermissionSet(permissionSet) {
      this.selectedUsers = []
      this.selectedPermissionSet = permissionSet
      this.fetchListeUsersAvecPermissionSets({ permissionSet })
      await this.fetchListePermissionSets({ permissionSet })
      if (permissionSet) {
        this.selectedPermission = []
        permissionSet.permissions.forEach(p => {
          this.selectedPermission[p.id] = true
        })
      }
    },
    async modifierPermissionSet() {
      if (this.permissionSetToUpdate.name != this.nameToUpdate) {
        const response = await this.updateNamePermissionSet({
          ensemble: this.permissionSetToUpdate,
          data: { name: this.nameToUpdate }
        })
        if (response) {
          this.hideModal('ModalUpdate')
        }
      }
    },
    async supprimerUserPermissionSet() {
      const response = await this.deleteUserPermissionSet({
        ensemble: this.selectedPermissionSet,
        usersToDelete: this.permissionSetToDeleteUsers
      })
      if (response) {
        this.hideModal('ModalDeleteUsers')
      }
    },
    async UpdateAutorisation(event, permission) {
      await this.updatePermissionPermissionSet({
        ensemble: this.selectedPermissionSet,
        permissionToAdd: permission,
        add: event
      })
    }
  },
  computed: {
    ...mapGetters([
      'getListePermission',
      'getListePermissionSet',
      'getListeUsers',
      'getPermissionLoading',
      'getPermissionUpdateLoading',
      'getPermissionError',
      'getUsersLoading',
      'getUpdateUsersLoading'
    ]),
    listUserComputed: function() {
      return this.getListeUsers.filter(user => {
        if (this.selectedPermissionSet.users != null) {
          return (
            this.selectedPermissionSet.users.findIndex(
              item => item.id === user.id
            ) < 0
          )
        }
      })
    }
  },
  async mounted() {
    this.initLoading = true
    await this.fetchListePermissionSet()
    this.initLoading = false
    this.fetchListePermission()
    this.fetchListeUsersPermission()
  }
}
</script>
<style lang="scss" scoped>
.permission-setting {
  .aucun-permission {
    color: #007bff;
    text-align: center;
  }
  .content {
    flex-wrap: nowrap;
    .col1 {
      border-right: 2px solid #eeeeee;
      max-width: 100%;
      .margin {
        margin: 12px;
      }
      .list-group {
        .list-group-item {
          display: flex;
          justify-content: space-between;
          .name-permissionSet {
            display: flex;
            .icon {
              color: #2dabe2;
              margin-right: 12px;
            }
            .capitalize {
              text-transform: capitalize;
            }
          }
          .iconDropDown {
            margin-right: 6px;
          }
          .right {
            margin-right: -20px;
            display: flex;
            justify-content: flex-end;

            .actions {
              display: block;
              margin-right: 20px;
              button {
                margin: 0 2px;
              }
            }
          }
        }
      }
    }
    .col2 {
      border-right: 2px solid #eeeeee;
      max-width: 100%;
      .bloc-select-multi {
        .postion-btn {
          display: flex;
          justify-content: flex-end;
          margin-top: 3px;
          .button-add-users {
            font-size: 11px;
          }
        }
      }
      .addListUsers {
        margin-top: 13px;
        .chargement {
          text-align: center;
          color: #2dabe2;
        }
        .list-group {
          padding-top: 5px;
          .list-group-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
          }
          .user {
            .type {
              color: #2dabe2;
              font-weight: normal;
              font-size: 12px;
            }
          }
          .button {
            cursor: pointer;
          }
        }
      }
    }
    .col3 {
      .chargement {
        color: #2dabe2;
        margin-bottom: 6px;
      }
    }
  }
  @media screen and (max-width: 580px) {
    .content {
      flex-wrap: unset;
    }
    .row {
      display: block;
      .col2 {
        margin-top: 10px;
      }
      .col3 {
        margin-top: 10px;
      }
    }
  }
}
</style>
<style lang="scss">
.permission-setting {
  .content {
    .col1 {
      .list-group {
        .list-group-item {
          .dropDown {
            .btn-link {
              font-weight: 400;
              color: #35495e;
              text-decoration: none;
            }
            .btn-lg,
            .btn-group-lg > .btn {
              font-size: 1.25rem;
              line-height: 0;
              border-radius: 0.3rem;
            }
          }
        }
      }
    }
  }
}
</style>
